
import {Component, Vue} from "vue-property-decorator";
import {getExcelJSONDataByList, getPageAPIs} from "@/common/util";
import {SummerRewardRecord} from "@/common/api/operationManage/summerRewardRecord";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import {shareConfirmOptions} from '@/common/config';

export const pageApiList = getPageAPIs(SummerRewardRecord);
const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};
const defaultForm = {
  phone:'',
  type:null,
  reward_name:'',
}
@Component({
})
export default class summerRewardRecord extends Vue {
  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
    this.classApi = new SummerRewardRecord();
    this.form = {...this.deepLoopUtil.deepCopy(defaultForm)}
    for(const item of this.typeArray){
      this.statusObject[item.value] = item.label
    }

  }
  private classApi: any;
  private typeArray = [
    {value:0,label:'谢谢惠顾'},
    {value:1,label:'实物'},
    {value:2,label:'钻石'},
    {value:3,label:'平台小时券'},
    {value:4,label:'全国会员'},
  ]
  private statusObject:any = {}
  private time = []
  private shopValue:any = {}
  private form: any = {}
  private deepLoopUtil: any = {}
  private downloadExcelLoading: boolean = false;
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    if(this.shopValue.id){
      this.form.shop_id = this.shopValue.id
    }
    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;
      this.form.start_date = startDate;
      this.form.end_date = endDate;
    }
    this.getTable();
  }


  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    'phone': '手机号',
    'user_name':'昵称',
    'type': '类型',
    'reward_name': '奖品名称',
    'reward_num': '数量',
    'address': '收货地址',
    'created_at':'获得时间'
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = Object.keys(this.userExcelList2ch)
  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.classApi.getList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    this.time = []
    this.search()

  }

  private onSendGoods(row:any):void{
    this.$confirm("确认发货吗？", '', {
      ...(shareConfirmOptions || {})
    }).then(() => {
      this.classApi.delivery({
        id: row.id
      }, () => {
        this.getTable();
      })
    })
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTable();
  }
  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserTableListParams("exportExcel");
    if (params === null) {
      return false;
    }
    this.downloadExcelLoading = true;
    this.classApi.exportList(params, (res: any) => {
          const { start_date, end_date } = params ;
          const filename = `暑假中奖记录 ${start_date}到${end_date}.xlsx`;
          const { data } = res ;

          const { list: originList } = data ;
          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                item.type =  this.statusObject[item.type]
                return item;
              })
              : [];

          if (newList.length > 0) {
            const userExcelSortList = this.userExcelSortList;

            const userExcelList2ch = this.userExcelList2ch;

            const excelJSONData = getExcelJSONDataByList(
                newList,
                userExcelSortList,
                userExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }
  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
      phone, // 手机号
      type,
      start_date, // 开始时间
      end_date, // 结束时间
    } = form ;

    const { pageNo, pageSize } = tablePage ;

    const outerParams: any = {};

    if (start_date) {
      outerParams.start_date = start_date;
    }

    if (end_date) {
      outerParams.end_date = end_date;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (type) {
      outerParams.type = type;
    }


    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;
      outerParams.start_date = startDate;
      outerParams.end_date = endDate;
    }

    if (mode == "exportExcel" && !(outerParams.start_date && outerParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

    }


    return outerParams;
  }
}
